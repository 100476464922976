import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { InterfaceGenericTypeSlug } from '@interfaces/InterfaceGenericTypeSlug.ts';
import { InterfaceGenericUuidList } from '@interfaces/InterfaceGenericUuidList.ts';
import memoize from 'lodash-es/memoize';

export interface InterfaceOption {
  readonly value: string;
  readonly label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly [key: string]: any;
}

export interface InterfaceOptionsArrayFromTypeFilter {
  key: string;
  matches: string;
}

export const getOptionsArrayFromUuidOrSlug = memoize(
  (
    types: InterfaceGenericMap<InterfaceGenericUuidList> | InterfaceGenericMap<InterfaceGenericTypeSlug>,
    filter?: InterfaceOptionsArrayFromTypeFilter,
  ): InterfaceOption[] => {
    let options: (InterfaceGenericUuidList | InterfaceGenericTypeSlug)[] = Object.values(types);
    let valueKey = 'uuid';
    if (options[0]?.slug !== undefined) {
      valueKey = 'slug';
    }
    if (filter) {
      options = options.filter((option) => option[filter.key] === filter.matches);
    }
    return options.map((type) => ({ value: String(type[valueKey]), label: type.name }));
  },
  (
    types: InterfaceGenericMap<InterfaceGenericUuidList> | InterfaceGenericMap<InterfaceGenericTypeSlug>,
    filter?: InterfaceOptionsArrayFromTypeFilter,
  ) => JSON.stringify(types) + JSON.stringify(filter),
);

export const getOptionValueFromSlug = (
  options: InterfaceOption[],
  keyValue: string,
  setDefault = true,
): InterfaceOption | null => {
  const optionIndex = options.findIndex((option) => option.value === keyValue);
  return options[optionIndex] ?? (setDefault ? options[0] : null);
};
