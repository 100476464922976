import { useAppSelector, useAppStore } from '@store/appStore.ts';
import { InterfaceRoute, setRoute } from '@store/reducers/routeReducer.ts';
import { selectAppTheme } from '@store/reducers/themeConfigReducer.tsx';
import { PropsWithChildren, useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

function App({ children }: PropsWithChildren) {
  const themeConfig = useAppSelector(selectAppTheme);
  const appStore = useAppStore();
  const { dispatch } = appStore;
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const route: InterfaceRoute = {
      location,
      params,
      searchParams: Object.fromEntries(searchParams.entries()),
    };
    dispatch(setRoute(route));
  }, [location, dispatch, params, searchParams]);

  return (
    <div
      className={`${themeConfig.isDarkMode ? 'dark' : ''} ${
        (themeConfig.sidebar && 'toggle-sidebar') || ''
      } ${themeConfig.menu} ${themeConfig.layout} main-section relative text-sm font-normal antialiased`}>
      {children}
    </div>
  );
}

export default App;
