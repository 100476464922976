import { Draft, PayloadAction } from '@reduxjs/toolkit';
import isEqual from 'lodash-es/isEqual';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sliceItem = (stateKey: string) => (state: Draft<any>, action: PayloadAction<any>) => {
  if (!isEqual(state[stateKey], action.payload)) {
    return { ...state, ...{ [stateKey]: action.payload } };
  }
};

const sliceItemElementGeneric =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (stateKey: string, objectKey: string) => (state: Draft<any>, action: PayloadAction<any>) => {
    if (
      state[stateKey] &&
      (!state[stateKey][action.payload[objectKey]] ||
        !isEqual(state[stateKey][action.payload[objectKey]], action.payload[objectKey]))
    ) {
      state[stateKey][action.payload[objectKey]] = action.payload;
      return state;
    }
  };

const sliceItemElementUuid = (objectKey: string) => {
  return sliceItemElementGeneric(objectKey, 'uuid');
};

export const sliceStateCheckAndUpdate = {
  sliceItem,
  sliceItemElementGeneric,
  sliceItemElementUuid,
};
