import { faGavel } from '@fortawesome/pro-duotone-svg-icons/faGavel';
import { faIdCard } from '@fortawesome/pro-duotone-svg-icons/faIdCard';
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList';
import { faTruck } from '@fortawesome/pro-duotone-svg-icons/faTruck';
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import { faVanShuttle } from '@fortawesome/pro-duotone-svg-icons/faVanShuttle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import { selectAppTheme, selectAppThemeIsSemiDark, toggleSidebar } from '@store/reducers/themeConfigReducer.tsx';
import { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink, useLocation } from 'react-router-dom';

import LogoNavLink from '@/components/Layouts/LogoNavlink';

const Sidebar = () => {
  const [currentMenu, setCurrentMenu] = useState<string>('');
  const isSemiDark = useAppSelector(selectAppThemeIsSemiDark);
  const themeConfig = useAppSelector(selectAppTheme);

  const location = useLocation();
  const dispatch = useAppDispatch();
  const toggleMenu = (value: string) => {
    setCurrentMenu((oldValue) => {
      return oldValue === value ? '' : value;
    });
  };

  useEffect(() => {
    const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add('active');
      const ul: HTMLUListElement | null = selector.closest('ul.sub-menu');
      if (ul) {
        const ele: HTMLElement | null = ul.closest('li.menu')?.querySelector('.nav-link') || null;
        if (ele) {
          setTimeout(() => {
            ele?.click();
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024 && themeConfig.sidebar) {
      dispatch(toggleSidebar());
    }
  }, [dispatch, location, themeConfig.sidebar]);

  return (
    <div className={isSemiDark ? 'dark' : ''}>
      <nav
        className={`sidebar fixed inset-y-0 z-50 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300 ${isSemiDark ? 'text-white-dark' : ''}`}>
        <div className="h-full bg-white dark:bg-black">
          <div className="flex items-center justify-between px-4 py-3">
            <LogoNavLink />

            <button
              type="button"
              className="collapse-icon flex size-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 dark:text-white-light dark:hover:bg-dark-light/10"
              onClick={() => dispatch(toggleSidebar())}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="m-auto size-5">
                <path
                  d="M13 19L7 12L13 5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  opacity="0.5"
                  d="M16.9998 19L10.9998 12L16.9998 5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <PerfectScrollbar className="relative h-[calc(100vh-80px)]">
            <ul className="relative space-y-0.5 p-4 py-0 font-semibold">
              {/* Courier */}
              <h2 className="-mx-4 mb-1 flex items-center border-y-2 bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
                <svg
                  className="hidden h-5 w-4 flex-none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                <span>Courier</span>
              </h2>
              <li className="menu nav-item">
                <NavLink to="/CourierDashboard" className="nav-link group">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faVanShuttle} />
                    <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      Courier Dashboard
                    </span>
                  </div>
                </NavLink>
              </li>
              <li className="menu nav-item">
                <NavLink to="/BiddingDashboard" className="nav-link group">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faGavel} />
                    <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      Bidding Dashboard
                    </span>
                  </div>
                </NavLink>
              </li>
              <li className="menu nav-item">
                <button
                  type="button"
                  className={`${currentMenu === 'users' ? 'active' : ''} nav-link group w-full`}
                  onClick={() => toggleMenu('users')}>
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faTruck} />
                    <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      Fleet Management
                    </span>
                  </div>

                  <div className={currentMenu === 'users' ? 'rotate-90' : ''}>
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9 5L15 12L9 19"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>

                <AnimateHeight duration={300} height={currentMenu === 'users' ? 'auto' : 0}>
                  <ul className="sub-menu light-blue-100">
                    <li>
                      <NavLink to="/FleetManagement">Dashboard</NavLink>
                    </li>
                    <li>
                      <NavLink to="/VehicleManage">Manage Vehicles</NavLink>
                    </li>
                    <li>
                      <NavLink to="/VehicleAdd">Add Vehicles</NavLink>
                    </li>
                    <li>
                      <NavLink to="/VehicleTypeAdd">Add Vehicle Type</NavLink>
                    </li>

                    {/* <li>
                      <NavLink to="/DriverAdd">Add Drivers</NavLink>
                    </li> */}
                  </ul>
                </AnimateHeight>
              </li>
              <li className="menu nav-item">
                <button
                  type="button"
                  className={`${currentMenu === 'company' ? 'active' : ''} nav-link group w-full`}
                  onClick={() => toggleMenu('company')}>
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faUser} />
                    <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      Company Users
                    </span>
                  </div>

                  <div className={currentMenu === 'users' ? 'rotate-90' : ''}>
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9 5L15 12L9 19"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </button>

                <AnimateHeight duration={300} height={currentMenu === 'company' ? 'auto' : 0}>
                  <ul className="sub-menu light-blue-100">
                    <li>
                      <NavLink to="/DriversManage">Manage Drivers</NavLink>
                    </li>
                    <li>
                      <NavLink to="/DriverAddCompany">Add Driver</NavLink>
                    </li>
                    <li>
                      <NavLink to="/AdminAddCompany">Add Admin</NavLink>
                    </li>
                  </ul>
                </AnimateHeight>
              </li>
              <li className="menu nav-item">
                <NavLink to="/BidDetail" className="nav-link group">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faList} />
                    <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      Bidding Detail
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Driver */}
              <h2 className="-mx-4 mb-1 flex items-center border-y-2 bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
                <svg
                  className="hidden h-5 w-4 flex-none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                <span>driver</span>
              </h2>
              <li className="menu nav-item">
                <NavLink to="/DriverDashboard" className="nav-link group">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faIdCard} />
                    <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      Driver Dashboard
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Company */}
              <h2 className="-mx-4 mb-1 flex items-center border-y-2 bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
                <svg
                  className="hidden h-5 w-4 flex-none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                <span>account</span>
              </h2>

              <li className="menu nav-item">
                <NavLink to="/AccountPages/CompanyProfile" className="nav-link group">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faIdCard} />
                    <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      Company Profile
                    </span>
                  </div>
                </NavLink>
              </li>
              <li className="menu nav-item">
                <NavLink to="/AccountPages/UserProfile" className="nav-link group">
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faIdCard} />
                    <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                      User Profile
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Client */}
              {/* Removed ShipperDash Link til Project is more clear */}

              {/* Support Tab */}
              <div className="bottom-item">
                <h2 className="-mx-4 mb-1 flex items-center bg-white-light/30 px-7 py-3 font-extrabold uppercase dark:bg-dark dark:bg-opacity-[0.08]">
                  <svg
                    className="hidden h-5 w-4 flex-none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                  <span>support</span>
                </h2>
                <li className="menu nav-item">
                  <NavLink to="https://courierboost.com" target="_blank" className="nav-link group">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faList} />
                      <span className="pl-3 text-black dark:text-[#506690] dark:group-hover:text-white-dark">
                        Support Desk
                      </span>
                    </div>
                  </NavLink>
                </li>
              </div>
            </ul>
          </PerfectScrollbar>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
