import { InterfaceDriverInfo } from '@interfaces/InterfaceDriverInfo.ts';
import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { InterfaceGenericTypeSlug } from '@interfaces/InterfaceGenericTypeSlug.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { meLogOut } from '@store/reducers/meReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import { thunkGet } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { defaultTypeDebounceTimeMs } from '@store/reducers/reducerHelpers/thunkHelperShared.ts';
import { getCompanyUsers } from '@store/reducers/usersReducer.ts';
import memoize from 'lodash-es/memoize';

import thunkPost from './reducerHelpers/thunkPost';

export interface DriverReducerState {
  driverStatusTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  driverStatusTypeColourMap: InterfaceGenericMap<string>;
  driverUuids: string[];
  driverInfo: InterfaceGenericMap<InterfaceDriverInfo>;
}

const driverStatusTypeColourMapDefault = {
  available: 'success',
  booked: 'cb_orange',
  maintenance: 'cb_red',
  'on-break': 'cb_lightblue',
  'on-leave': 'cb_lightblue',
  offline: 'cb_red',
  'on-delivery': 'cb_lightblue',
  unavailable: 'cb_red',
};

const initialState: DriverReducerState = {
  // driverStatusTypes: {},
  driverStatusTypeColourMap: driverStatusTypeColourMapDefault,
  driverUuids: [],
  driverInfo: {},
  driverStatusTypes: {},
};

export const sliceName = 'driver';

export const driverSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(meLogOut.fulfilled, () => initialState)
      .addCase(getDriverStatusTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('driverStatusTypes'))
      .addCase(getCompanyDriverUserUuid.fulfilled, sliceStateCheckAndUpdate.sliceItem('driverUuids'))
      .addCase(getCompanyDriverInfo.fulfilled, sliceStateCheckAndUpdate.sliceItem('driverInfo'));
  },
});

// export const { } = driverSlice.actions;

export const getDriverStatusTypes = thunkGet.get(sliceName, 'type/driver/status');
export const getCompanyDriverUserUuid = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/driver/user/uuid');
export const getCompanyDriverInfo = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/driver/info');

export interface AddDriverFormValues {
  email: string;
  firstname: string;
  lastname: string;
  countryIsoCode: string;
  phoneNumbers: {
    countryIsoCode: string;
    phoneNumber: string;
  }[];
  licenceNumber: string;
  insuranceNumber: string;
}
export const addCompanyDriver = thunkPost.compUrl<AddDriverFormValues>(sliceName, '/c/{companySlug}/admin/driver', [
  getCompanyUsers(),
  getCompanyDriverUserUuid(),
  getCompanyDriverInfo(),
]);

export interface FormUpdateDriverStatus {
  driverUserUuid: string;
  driverStatusTypeSlug: string;
}

export const updateCompanyDriverStatus = thunkPost.compUrl<FormUpdateDriverStatus>(
  sliceName,
  '/c/{companySlug}/admin/driver/status',
  [getCompanyDriverInfo()],
);

// export const {} = driverSlice.actions;

export const selectDriverState = (state: AppState) => state.driver;
export const selectDriverStatusTypes = (state: AppState) => selectDriverState(state).driverStatusTypes;
export const selectDriverStatusTypeColourMap = (state: AppState) => selectDriverState(state).driverStatusTypeColourMap;

export const selectDriverUuids = memoize((state: AppState) => selectDriverState(state).driverUuids);
export const selectDriverInfo = memoize((state: AppState) => selectDriverState(state).driverInfo);

export default driverSlice.reducer;

// Only for initial load of data that doesn't require authentication
// This is only done to speed things up. It is not required.
// This data should be reloaded in the pages that need it.
// Load any data a page needs in the page. Else it will not update ever.
export const loadInitialDriverData = createDebouncedAsyncThunk(
  `${sliceName}/initial`,
  async (_, { dispatch }) => {
    dispatch(getDriverStatusTypes());
  },
  defaultTypeDebounceTimeMs,
);
