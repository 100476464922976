import { InterfaceAddress } from '@interfaces/InterfaceAddress.ts';
import { InterfaceDelivery } from '@interfaces/InterfaceDelivery.ts';
import { InterfaceDeliveryContacts } from '@interfaces/InterfaceDeliveryContacts.ts';
import { InterfaceDeliveryLocationHistory } from '@interfaces/InterfaceDeliveryLocationHistory.ts';
import { InterfaceDeliveryQuote } from '@interfaces/InterfaceDeliveryQuote.ts';
import { InterfaceDeliveryStatus } from '@interfaces/InterfaceDeliveryStatus.ts';
import { InterfaceDeliveryStep } from '@interfaces/InterfaceDeliveryStep.ts';
import { InterfaceDeliveryStepTask } from '@interfaces/InterfaceDeliveryStepTask.ts';
import { InterfaceDeliveryStepTaskDoc } from '@interfaces/InterfaceDeliveryStepTaskDoc.ts';
import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { InterfaceGenericTypeSlug } from '@interfaces/InterfaceGenericTypeSlug.ts';
import { InterfaceInterfaceDeliveryVehicleDrivers } from '@interfaces/InterfaceInterfaceDeliveryVehicleDrivers.ts';
import { InterfaceParcel } from '@interfaces/InterfaceParcel.ts';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore.ts';
import { meLogOut } from '@store/reducers/meReducer.ts';
import { addSuccessNotification } from '@store/reducers/notificationReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import { thunkGet } from '@store/reducers/reducerHelpers/thunkGet.ts';
import { defaultTypeDebounceTimeMs } from '@store/reducers/reducerHelpers/thunkHelperShared.ts';
import { thunkPost } from '@store/reducers/reducerHelpers/thunkPost.ts';

export interface DeliveryReducerState {
  deliveryCancelTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  deliveryLiveStatusTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  deliveryScheduleWindowTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  deliveryQuoteStatusTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  deliveryStepTasksTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  deliveries: InterfaceGenericMap<InterfaceDelivery>;
  deliverySteps: InterfaceGenericMap<InterfaceDeliveryStep>;
  deliveryStepTasks: InterfaceGenericMap<InterfaceDeliveryStepTask>;
  deliveryStatuses: InterfaceGenericMap<InterfaceDeliveryStatus>;
  deliveryStepTaskDocuments: InterfaceGenericMap<InterfaceDeliveryStepTaskDoc>;
  deliveryParcels: InterfaceGenericMap<InterfaceParcel>;
  deliveryAddresses: InterfaceGenericMap<InterfaceAddress>;
  deliveryContacts: InterfaceGenericMap<InterfaceDeliveryContacts>;
  deliveryQuotes: InterfaceGenericMap<InterfaceDeliveryQuote>;
  deliveryLocationHistory: InterfaceGenericMap<InterfaceDeliveryLocationHistory>;
  deliveryVehicleDrivers: InterfaceInterfaceDeliveryVehicleDrivers;
}

const initialState: DeliveryReducerState = {
  deliveryCancelTypes: {},
  deliveryLiveStatusTypes: {},
  deliveryScheduleWindowTypes: {},
  deliveryQuoteStatusTypes: {},
  deliveryStepTasksTypes: {},
  deliveries: {},
  deliverySteps: {},
  deliveryStepTasks: {},
  deliveryStatuses: {},
  deliveryStepTaskDocuments: {},
  deliveryParcels: {},
  deliveryAddresses: {},
  deliveryContacts: {},
  deliveryQuotes: {},
  deliveryLocationHistory: {},
  deliveryVehicleDrivers: {},
};

export const sliceName = 'delivery';

export const deliverySlice = createSlice({
  name: sliceName,
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(meLogOut.fulfilled, () => initialState)
      .addCase(getDeliveryCancelTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryCancelTypes'))
      .addCase(getDeliverLiveStatusTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryLiveStatusTypes'))
      .addCase(
        getDeliveryScheduleWindowTypes.fulfilled,
        sliceStateCheckAndUpdate.sliceItem('deliveryScheduleWindowTypes'),
      )
      .addCase(getDeliveryQuoteStatusTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryQuoteStatusTypes'))
      .addCase(getDeliveryStepTasksTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryStepTasksTypes'))
      .addCase(getCompanyDeliveries.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveries'))
      .addCase(getDeliverySteps.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliverySteps'))
      .addCase(getDeliveryStepTasks.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryStepTasks'))
      .addCase(getCompanyDeliveryStatuses.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryStatuses'))
      .addCase(updateDeliveryStatus.fulfilled, sliceStateCheckAndUpdate.sliceItemElementUuid('deliveryStatuses'))
      .addCase(
        getCompanyDeliveryStepTaskDocuments.fulfilled,
        sliceStateCheckAndUpdate.sliceItem('deliveryStepTaskDocuments'),
      )
      .addCase(getDeliveryParcels.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryParcels'))
      .addCase(getDeliveryCompanyAddresses.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryAddresses'))
      .addCase(getDeliveryCompanyContacts.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryContacts'))
      .addCase(getDeliveryQuotes.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryQuotes'))
      .addCase(getDeliveryVehicleDrivers.fulfilled, sliceStateCheckAndUpdate.sliceItem('deliveryVehicleDrivers'))
      .addCase(
        getCompanyDeliveryLocationHistory.fulfilled,
        sliceStateCheckAndUpdate.sliceItem('deliveryLocationHistory'),
      );
  },
});

export const getDeliveryCancelTypes = thunkGet.get(sliceName, 'type/delivery/cancel');
export const getDeliverLiveStatusTypes = thunkGet.get(sliceName, 'type/delivery/live/status');
export const getDeliveryScheduleWindowTypes = thunkGet.get(sliceName, 'type/delivery/schedule/window');
export const getDeliveryQuoteStatusTypes = thunkGet.get(sliceName, 'type/delivery/quote/status');
export const getDeliveryStepTasksTypes = thunkGet.get(sliceName, 'type/delivery/step/task');

export const getDeliverySteps = thunkGet.compUrl(sliceName, `/c/{companySlug}/admin/delivery/step`);
export const getDeliveryStepTasks = thunkGet.compUrl(sliceName, `/c/{companySlug}/admin/delivery/step/task`);
export const getDeliveryParcels = thunkGet.compUrl(sliceName, `/c/{companySlug}/admin/delivery/parcel`);
export const getDeliveryQuotes = thunkGet.compUrl(sliceName, `/c/{companySlug}/admin/delivery/quote`);
export const getCompanyDeliveries = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/delivery');
export const getCompanyDeliveryStatuses = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/delivery/status');
export const getCompanyDeliveryStepTaskDocuments = thunkGet.compUrl(
  sliceName,
  '/c/{companySlug}/admin/delivery/step/task/doc',
);
export const getDeliveryCompanyAddresses = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/delivery/address');
export const getDeliveryCompanyContacts = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/delivery/contact');
export const getCompanyDeliveryLocationHistory = thunkGet.compUrl(
  sliceName,
  '/c/{companySlug}/admin/delivery/location',
);

export const getDeliveryVehicleDrivers = thunkGet.compUrl(sliceName, '/c/{companySlug}/admin/delivery/vehicle/driver');

export interface InterfaceUpdateDeliveryVehicleForm {
  vehicleUuids: string[];
}

export interface InterfaceDeliveryReplace {
  '{deliveryUuid}': string;
}

export const addDeliveryVehicle = thunkPost.compUrl<InterfaceUpdateDeliveryVehicleForm, InterfaceDeliveryReplace>(
  sliceName,
  '/c/{companySlug}/admin/delivery/{deliveryUuid}/vehicle',
  [getCompanyDeliveries(), getDeliveryVehicleDrivers()], // Get updated list of deliveries after change
);

export interface InterfaceUpdateDeliveryVehicleDriverForm {
  vehicleUuids: {
    [vehicleUuids: string]: {
      [vehicleUuids: string]: string[];
    };
  };
}

export const addDeliveryVehicleDriver = thunkPost.compUrl<
  InterfaceUpdateDeliveryVehicleDriverForm,
  InterfaceDeliveryReplace
>(sliceName, '/c/{companySlug}/admin/delivery/{{deliveryUuid}}/vehicle/driver', [
  getCompanyDeliveries(),
  getCompanyDeliveryStatuses(),
  getDeliveryVehicleDrivers(),
  addSuccessNotification({
    title: 'Linked Vehicle and Driver',
    message: 'Linked Vehicle and Drive',
  }),
]);

export interface InterfaceUpdateDeliveryStatusForm {
  status: string;
}

export const addDeliveryStatusUpdate = thunkPost.compUrl<InterfaceUpdateDeliveryStatusForm, InterfaceDeliveryReplace>(
  sliceName,
  '/c/{companySlug}/admin/delivery/{deliveryUuid}/status',
  [getCompanyDeliveries()], // Get updated list of deliveries after change
);

export const updateDeliveryStatus = thunkPost.compUrl<
  { deliveryLiveStatus: string; lat: number; lng: number },
  { '{deliveryUuid}': string }
>(sliceName, '/c/{companySlug}/admin/delivery/{deliveryUuid}/status', [
  getCompanyDeliveries(),
  getCompanyDeliveryStatuses(),
]);

export interface InterfaceUploadProofPhotoForm {
  deliveryStepTaskUuid: string;
  deliveryStepTaskProofTypeSlug: string;
  file: File;
}

export interface InterfaceUploadProofPhotoReplace {
  '{deliveryUuid}': string;
}

export const uploadDeliveryProofPhoto = thunkPost.compUrl<FormData, InterfaceUploadProofPhotoReplace>(
  sliceName,
  '/c/{companySlug}/admin/delivery/{deliveryUuid}/doc/step/task/proof',
  [getCompanyDeliveries(), getCompanyDeliveryStatuses(), getCompanyDeliveryStepTaskDocuments()],
);

export const selectDeliveryState = (state: AppState) => state.delivery;
export const selectDeliveries = (state: AppState) => selectDeliveryState(state).deliveries;
export const selectDelivery = (deliveryUuid: string | undefined) => (state: AppState) => {
  if (deliveryUuid !== undefined) {
    return selectDeliveries(state)[deliveryUuid];
  }
  return undefined;
};
export const selectDeliveriesActive = createSelector([selectDeliveries], (deliveries) => {
  return Object.fromEntries(Object.entries(deliveries).filter(([, delivery]) => delivery.isActive));
});
export const selectCompletedDeliveries = createSelector([selectDeliveries], (deliveries) => {
  return Object.fromEntries(
    Object.entries(deliveries).filter(([, delivery]) => delivery.deliveryLiveStatusTypeSlug === 'completed'),
  );
});
export const selectDeliveryLiveStatusTypes = (state: AppState) => selectDeliveryState(state).deliveryLiveStatusTypes;
export const selectDeliveryLiveStatusType =
  (deliveryLiveStatusTypeSlug: string | undefined) =>
  (state: AppState): InterfaceGenericTypeSlug | undefined =>
    selectDeliveryLiveStatusTypes(state)[deliveryLiveStatusTypeSlug ?? ''];
export const selectAllDeliverySteps = (state: AppState) => selectDeliveryState(state).deliverySteps;
export const selectDeliveryStep =
  (deliveryStepUuid: string) =>
  (state: AppState): InterfaceDeliveryStep | undefined =>
    selectAllDeliverySteps(state)[deliveryStepUuid];

export const selectSingleDeliverySteps = (delivery: InterfaceDelivery | undefined) =>
  createSelector([selectAllDeliverySteps], (deliverySteps) => {
    if (delivery === undefined) {
      return [];
    }
    return delivery.deliverySteps
      .filter((deliveryStepSimple) => {
        return deliverySteps[deliveryStepSimple.deliveryStepUuid] !== undefined;
      })
      .map((deliveryStepSimple) => deliverySteps[deliveryStepSimple.deliveryStepUuid]);
  });

export const selectDeliveryStepTasks = (state: AppState) => selectDeliveryState(state).deliveryStepTasks;
export const selectDeliveryStepTask =
  (deliveryStepTaskUuid: string) =>
  (state: AppState): InterfaceDeliveryStepTask | undefined =>
    selectDeliveryStepTasks(state)[deliveryStepTaskUuid];

export const selectSingleDeliveryStepTasks = (delivery: InterfaceDelivery | undefined) =>
  createSelector(
    [selectSingleDeliverySteps(delivery), selectDeliveryStepTasks],
    (deliverySteps, deliveryStepTasks): InterfaceGenericMap<InterfaceDeliveryStepTask> => {
      return deliverySteps.reduce((collector: InterfaceGenericMap<InterfaceDeliveryStepTask>, deliveryStep) => {
        if (deliveryStep && deliveryStep.deliveryStepTasks) {
          deliveryStep.deliveryStepTasks.forEach((deliveryStepTaskSimple) => {
            collector[deliveryStepTaskSimple.deliveryStepTaskUuid] =
              deliveryStepTasks[deliveryStepTaskSimple.deliveryStepTaskUuid];
          });
        }
        return collector;
      }, {});
    },
  );

export const selectDeliveryAddresses = (state: AppState) => selectDeliveryState(state).deliveryAddresses;
export const selectDeliveryAddress =
  (addressUuid: string | undefined) =>
  (state: AppState): InterfaceAddress | undefined => {
    if (addressUuid === undefined) {
      return undefined;
    }
    return selectDeliveryAddresses(state)[addressUuid];
  };

export const selectAllDeliveryParcels = (state: AppState) => selectDeliveryState(state).deliveryParcels;
export const selectAllDeliveryStepTaskDocuments = (state: AppState) =>
  selectDeliveryState(state).deliveryStepTaskDocuments;
export const deliveryStepTasksTypes = (state: AppState) => selectDeliveryState(state).deliveryStepTasksTypes;
export const deliveryStepTasksTypeName = (deliveryStepTasksTypeSlug: string | undefined) => (state: AppState) =>
  deliveryStepTasksTypeSlug
    ? (deliveryStepTasksTypes(state)[deliveryStepTasksTypeSlug].name ?? deliveryStepTasksTypeSlug)
    : deliveryStepTasksTypeSlug;

export const selectCompanyDeliveryStatuses = (state: AppState) => selectDeliveryState(state).deliveryStatuses;
export const selectCompanyDeliveryStatus =
  (deliveryUuid: string | undefined) =>
  (state: AppState): InterfaceDeliveryStatus | undefined => {
    return selectCompanyDeliveryStatuses(state)[deliveryUuid ?? ''];
  };

export default deliverySlice.reducer;

// Only for initial load of data that doesn't require authentication
// This is only done to speed things up. It is not required.
// This data should be reloaded in the pages that need it.
// Load any data a page needs in the page. Else it will not update ever.
export const loadInitialDeliveryData = createDebouncedAsyncThunk(
  `${sliceName}/initial`,
  async (_, { dispatch }) => {
    dispatch(getDeliveryCancelTypes());
    dispatch(getDeliverLiveStatusTypes());
    dispatch(getDeliveryScheduleWindowTypes());
    dispatch(getDeliveryQuoteStatusTypes());
    dispatch(getDeliveryStepTasksTypes());
  },
  defaultTypeDebounceTimeMs,
);
